<template>
  <div >
     <div class="CopBox" >
          <!-- <img  class="getCoupon"
                  alt="GetCoupon"
                  src="@/assets/MemberLevelBg.png"> -->
            <div class="ConLeft">
                <div>当前等级</div>
                <div>
                  <span>{{info.memberStoreLevel}}.</span>{{memberLevelName}}
                  <!-- {{info.memberStoreLevel}}{{info.memberLevelName}} -->
                </div>
                <div>尊贵特权持有中</div>
            </div>
            <div class="ConRight">
                <div>
                  <span @click="JumpLevelDes">等级说明</span>
                </div>
                <div>
                  当前成长值
                  <span> {{ info.growthValue }} </span>
                </div>
                <div>
                  <el-progress :percentage="percentage" ></el-progress>
                </div>
                <!--  && info.growthValue>250000 -->
                <div  v-if="info.memberStoreLevel=='V8' ">
                  您已经超过该等级
                  <span   @click="JumpLevelList">查看 〉</span>
                </div>
                <div  v-else>
                  还需 <b>{{LevelList[LevelIndex].min-info.growthValue+1}} </b>成长值可升级 <b>{{LevelList[LevelIndex].name}}.</b>{{LevelList[LevelIndex].memberLevelName}}
                  <span  @click="JumpLevelList">查看 〉</span>
                </div>
            </div>
        </div>
        <div class="LabelBox">
          <p>
            月享礼券
            <!-- <span class="GetCouponMsg" @click="jumpCoupon">我的优惠券 <i class="el-icon-arrow-right" aria-hidden="true"></i></span> -->
          </p>
        </div>
      
        <div class="CouponBox" >
            <el-col class="getCouponBox" :gutter="24">

                <!-- 待使用 -->
                <!-- <CouponItemLevel v-for='(item,index) in couponlist.couponCanReceiveList' :key="index" :span="8" :couponItem="item"></CouponItemLevel> -->
                <!-- 已抢光  或待领取的 -->
                <!-- <CouponItemLevel v-for='(item,index) in couponlist.memberStoreReceiveCouponVOList' :key="index+100" :span="8" :couponItem="item"></CouponItemLevel> -->

                <CouponItemLevel v-for='(item,index) in couponlist' :key="index" :span="8" :couponItem="item"></CouponItemLevel>
            </el-col>
        </div>


        <el-dialog
          title="平台会员等级说明"
          :visible.sync="dialogVisible"
          width="50%"
          center
          >
          <MemberLevelRule></MemberLevelRule>
          <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="dialogVisible = false">关  闭</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<style lang="less" scoped>
/deep/.el-progress-bar{
         padding: 0;
}
/deep/.el-progress-bar__outer{
 background: #8f96b1;
}
/deep/.el-progress-bar__inner{
  background: linear-gradient(98deg, #FEF5E3 0%, #FED595 100%);
}
/deep/.el-progress__text{
   display: none;
}
.CopBox{
  width: 1200px;
   margin:30px auto;
   height: 220px;
   background: url('.././assets/MemberLevelBg.png') no-repeat;
   background-size:100% 100%;
  //  .getCoupon{
  //     width: 100%;
  //     height: 100%;
  //  }
  .ConLeft{
    width: calc( 40% - 50px);
    height:170px;
    // background: red;
    padding:25px;
    float: left;
    div:nth-child(1){
      margin-top:20px;
      width: 78px;
      height: 25px;
      background: rgba(255,255,255,0.58);
      border-radius: 12px;
      border-bottom-left-radius: 0;
      opacity: 0.6;
      line-height: 25px;
      color: #BC6A06;
      font-size: 14px;
      text-align: center;
      border: 1px solid #BC6A06;
      margin-bottom: 15px;
    }
    div:nth-child(2){
      width:160px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5E2705;
      line-height:48px;
      margin-bottom: 8px;
       span{
        width: 67px;
        height: 48px;
        font-size: 34px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5E2705;
        line-height: 48px;
       }
    }
    div:nth-child(3){
      width: 115px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5E2705;
      line-height: 22px;
    }
  }
  .ConRight{
    width: calc( 60% - 110px);
    height:174px;
    padding:23px 50px 23px  60px;
    float: left;
    b{
      font-weight: bold;
    }
    div:nth-child(1){
      height: 20px;
      width: 100%;
      span{
        float: right;
        width: 91px;
        height: 30px;
        background: #292E41;
        border-radius: 100px;
        opacity: 0.6;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height:30px;
        cursor: pointer;
      }
    }
    div:nth-child(2){
      height:65px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dfe1e8;
      line-height:65px;
      margin-bottom: 8px;
        span{
          width: 106px;
          height: 65px;
          font-size: 46px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 65px;
          letter-spacing: 1px;
        }
    }
    div:nth-child(3){
      margin-bottom:16px;
    }
    div:nth-child(4){
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dfe1e8;
      line-height: 22px;
      span{
        float: right;
      }
    }
  }
}
.CouponBox{
  width: 1200px;
   margin:0px auto;
}
.LabelBox{
  width: 100%;
  height:60px;
  // background: #fff;
  margin-bottom: 20px;
  p{
     width: 1200px;
     margin:0px auto;
     line-height: 60px;
     text-align: center;
        font-size:34px;
        color: #333;
        font-weight: bold;
      .GetCouponMsg{
        color: #666;
        font-size: 14px;
        text-align: center;
        float:right;
        cursor: pointer;
      }
  }
}

 .coupon:nth-child(3n + 0) {
      margin-right: 0px;
    }
</style>

<script>
import {MemberInfo, GetMouthCouponList} from 'api/member.js';
const CouponItemLevel = ()=>import("components/common/CouponItemLevel.vue");
const MemberLevelRule = () => import("views/MemberLevelRule.vue")
export default {
  name:"GetCoupon",
   data() {
      return {
           couponlist:'',
           total:0,
           percentage:0,
         
          info:'',
          dialogVisible:false,
          memberLevelName:'',
          LevelList:[
            {
              name:"V1",
              min:0,
              max:3000,
              memberLevelName:'普通会员'
            },
            {
              name:"V2",
              min:3000,
              max:5000,
              memberLevelName:'标准会员'
            },
            {
              name:"V3",
              min:5000,
              max:10000,
              memberLevelName:'VIP会员'
            },
            {
              name:"V4",
              min:10000,
              max:20000,
              memberLevelName:'五星会员'
            },
            {
              name:"V5",
              min:20000,
              max:50000,
              memberLevelName:'金牌会员'
            },
            {
              name:"V6",
              min:50000,
              max:100000,
              memberLevelName:'钻石会员'
            },
            {
              name:"V7",
              min:100000,
              max:250000,
              memberLevelName:'超级会员'
            },
            {
              name:"V8",
              min:250000,
              max:'',
              memberLevelName:'至尊会员'
            }
          ],
          LevelIndex:0,

      }
   },
   created() {
    MemberInfo().then((res) => {
        this.info = res.data.data;
        if(this.info.memberStoreLevel=='V1'){
          this.memberLevelName='普通会员'
        }else if(this.info.memberStoreLevel=='V2'){
          this.memberLevelName='标准会员'
        }else if(this.info.memberStoreLevel=='V3'){
          this.memberLevelName='VIP会员'
        }else if(this.info.memberStoreLevel=='V4'){
          this.memberLevelName='五星会员'
        }else if(this.info.memberStoreLevel=='V5'){
          this.memberLevelName='金牌会员'
        }else if(this.info.memberStoreLevel=='V6'){
          this.memberLevelName='钻石会员'
        }else if(this.info.memberStoreLevel=='V7'){
          this.memberLevelName='超级会员'
        }else{
          this.memberLevelName='至尊会员'
        }
        console.log('9999999999',this.info.memberStoreLevel+this.info.memberLevelName)
        console.log('9999999999成长值',this.info.growthValue)
        console.log('9999999999会员等级',this.info.memberStoreLevel)
        this.LevelList.forEach((item,i) => {
          if(item.name==this.info.memberStoreLevel){
            this.LevelIndex=i+1
          }
        });

        this.getCouponList()

          if(this.info.comboTotal==0){
                this.percentage=Number(0)
            }else{
                this.percentage=parseFloat((this.info.growthValue / this.LevelList[this.LevelIndex].min*100).toFixed(2))
            } 
      })
   },
   methods:{
    JumpLevelDes(){
      this.dialogVisible = true
    },
    JumpLevelList(){
      this.$router.push({ name: "LevelList",query:{nums:this.info.growthValue} });
    },
      //  获取优惠券列表
    getCouponList(){
        let params={
          // current:this.current,
          // size:this.size,
          memberLevel:this.LevelIndex
        }

        console.log(params)
        GetMouthCouponList(params).then(data=>{
          console.log(data.data.data)
          this.couponlist=data.data.data
           this.total = Number(data.data.data.length);
        })
    },
    // jumpCoupon() {
    //   this.$router.push({ name: "Coupon" });
    // },
   },
   
  components:{
    CouponItemLevel,
    MemberLevelRule
  } 
};
</script>
